import React from "react";

function Footer() {
  
  return (
    <div>
      {/* footer */}
      <div id="tt-footer">
          <div className="tt-footer-inner">
            {/* Begin footer column 
                ========================= */}
            <div className="footer-col tt-align-center-left">
              <div className="footer-col-inner">
                {/* You can use whatever button or link here */}
                <div className="tt-btn tt-btn-link">
                  <a href="#" className="scroll-to-top" data-hover="Back to top">
                    Back to top
                  </a>
                </div>
              </div>{" "}
              {/* /.footer-col-inner */}
            </div>
            {/* Begin footer column */}
            {/* Begin footer column 
                ========================= */}
            <div className="footer-col tt-align-center order-m-last">
              <div className="footer-col-inner">
                <div className="tt-copyright">© Copyright - Scribble DM</div>
              </div>{" "}
              {/* /.footer-col-inner */}
            </div>
            {/* Begin footer column */}
            {/* Begin footer column 
                ========================= */}
            <div className="footer-col tt-align-center-right">
              <div className="footer-col-inner">
                <div className="footer-social">
                  <div className="footer-social-text">
                    <span>Follow</span>
                    <i className="fas fa-share-alt" />
                  </div>
                  <div className="social-buttons">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/ScribbleDM"
                          className="magnetic-item"
                          target="_blank"
                          rel="noopener"
                        >
                          Fb.
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/scribble.dm/"
                          className="magnetic-item"
                          target="_blank"
                          rel="noopener"
                        >
                          Ig.
                        </a>
                      </li>
                    </ul>
                  </div>{" "}
                  {/* /.social-buttons */}
                </div>{" "}
                {/* /.footer-social */}
              </div>{" "}
              {/* /.footer-col-inner */}
            </div>
            {/* Begin footer column */}
          </div>{" "}
          {/* /.tt-section-inner */}
        </div>

      {/* //footer */}
    </div>
  );
}

export default Footer;