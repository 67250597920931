import React, { useState } from "react";
// import $ from 'jquery';
function Header() {
 
  return (
    <div id="tt-header" className="tt-header-fixed">
        <div className="tt-header-inner">
          {/* add/remove class "tt-wrap" to enable/disable element boxed layout (class "tt-boxed" is required in <body> tag!). Note: additionally you can use prepared helper class "max-width-*" to add custom width to "tt-wrap". Example: "max-width-1500" (class "tt-wrap" is still required!). More info about helper classes can be found in the file "helper.css". */}
          <div className="tt-header-col">
            {/* Begin logo 
                ================ */}
            <div className="tt-logo">
              <a href="/">
                {/* Hint: You may need to change the img height to match your logo type. You can do this from the "theme.css" file (find: ".tt-logo img"). */}
                <img
                  src="assets/img/logo-light.png"
                  className="tt-logo-light magnetic-item"
                  alt="Logo"
                />
                {/* logo light */}
                <img
                  src="assets/img/logo-light.png"
                  className="tt-logo-dark magnetic-item"
                  alt="Logo"
                />
                {/* logo dark */}
              </a>
            </div>
            {/* End logo */}
          </div>{" "}
          {/* /.tt-header-col */}
          <div className="tt-header-col">
            {/* Begin overlay menu toggle button */}
            <div id="tt-ol-menu-toggle-btn-wrap">
              <div className="tt-ol-menu-toggle-btn-text">
                <span className="text-menu" data-hover="Open">
                  Menu
                </span>
                <span className="text-close">Close</span>
              </div>
              <div className="tt-ol-menu-toggle-btn-holder">
                <a href="#" className="tt-ol-menu-toggle-btn magnetic-item">
                  <span />
                </a>
              </div>
            </div>
            {/* End overlay menu toggle button */}
            {/* Begin overlay menu 
                ======================== 
                * Use class "tt-ol-menu-center" to align menu to center.
                * Use class "tt-ol-menu-count" to enable menu counter.
                */}
            <nav className="tt-overlay-menu tt-ol-menu-center tt-ol-menu-count">
              <div className="tt-ol-menu-holder">
                <div className="tt-ol-menu-inner tt-wrap">
                  <div className="tt-ol-menu-content">
                    {/* Begin menu list */}
                    <ul className="tt-ol-menu-list">
                      {/* <li><a href="">Single Link</a></li> */}
                      {/* Begin submenu (submenu master)
                          ==================================== */}
                      <li className="tt-ol-submenu-wrap active">
                        <div className="tt-ol-submenu-trigger">
                          <a href="#">Home</a>
                          <div className="tt-ol-submenu-caret-wrap">
                            <div className="tt-ol-submenu-caret magnetic-item" />
                          </div>{" "}
                          {/* /.tt-ol-submenu-caret-wrap */}
                        </div>{" "}
                        {/* /.tt-ol-submenu-trigger */}
                        <div className="tt-ol-submenu">
                          <ul className="tt-ol-submenu-list">
                            <li className="active">
                              <a href="/">Home Page</a>
                            </li>
                          </ul>{" "}
                          {/* /.tt-ol-submenu-list */}
                        </div>{" "}
                        {/* /.tt-ol-submenu */}
                      </li>
                      {/* End submenu (sub-master) */}
                      {/* Begin submenu (submenu master)
                          ==================================== */}
                
                      {/* End submenu (sub-master) */}
                      {/* Begin submenu (submenu master)
                          ==================================== */}
                
                      {/* End submenu (sub-master) */}
                      {/* End submenu */}
                    
                    </ul>
                    {/* End menu list */}
                  </div>{" "}
                  {/* /.tt-ol-menu-content */}
                </div>{" "}
                {/* /.tt-ol-menu-inner */}
              </div>{" "}
              {/* /.tt-ol-menu-holder */}
              {/* Begin noise background */}
              <div className="bg-noise" />
              {/* End noise background */}
            </nav>
            {/* End overlay menu */}
          </div>{" "}
          {/* /.header-col */}
        </div>{" "}
        {/* /.header-inner */}
      </div>
  );
}

export default Header;